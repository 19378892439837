export default [
  {
    route: 'home',
    title: 'Inicio',
    icon: 'HomeIcon'
  },
  {
    header: 'Usuarios',
    icon: 'UserIcon',
    children: [
      {
        route: 'users',
        title: 'Usuarios',
        icon: 'UsersIcon'
      },
      {
        route: 'roles',
        title: 'Roles',
        icon: 'ListIcon'
      },
      {
        route: 'permissions',
        title: 'Permisos',
        icon: 'CheckSquareIcon'
      }
    ]
  },
  {
    header: 'Proyectos',
    icon: 'BriefcaseIcon',
    children: [
      {
        route: 'projects',
        title: 'Proyectos',
        icon: 'ArchiveIcon'
      },
      {
        route: 'properties',
        title: 'Unidades',
        icon: 'GridIcon'
      }
    ]
  },
  {
    header: 'Ventas',
    icon: 'CreditCardIcon',
    children: [
      {
        route: 'quotations',
        title: 'Cotizaciones',
        icon: 'FileTextIcon'
      },
      {
        title: 'Reservas',
        icon: 'FolderIcon',
        children: [
          {
            route: 'bookings-requests',
            title: 'Solicitudes',
            icon: 'BookOpenIcon'
          },
          {
            route: 'bookings-orders',
            title: 'Pagos',
            icon: 'BookOpenIcon'
          }
        ]
      }
      /*{
        title: 'Promesas',
        icon: 'BookOpenIcon',
        children: [
          {
            route: 'promises-requests',
            title: 'Solicitudes',
            icon: 'BookOpenIcon',
          },
          {
            route: 'promises-orders',
            title: 'Pagos',
            icon: 'BookOpenIcon',
          }
        ]
      },*/
    ]
  },
  {
    route: 'business',
    title: 'Negocios',
    icon: 'DollarSignIcon'
  },
  {
    header: 'Inmobiliarias',
    icon: 'FolderIcon',
    children: [
      {
        route: 'agencies-create',
        title: 'Nueva inmobiliaria',
        icon: 'PlusSquareIcon'
      },
      {
        route: 'agencies',
        title: 'Ver Inmobiliarias',
        icon: 'EyeIcon'
      }
    ]
  },
  {
    route: 'preapprovals',
    title: 'Preaprobados',
    icon: 'FileTextIcon'
  },
  {
    route: 'news',
    title: 'Noticias',
    icon: 'ClipboardIcon',
  },
  {
    route: 'comments',
    title: 'Comentarios',
    icon: 'MessageCircleIcon',
  },
  {
    header: 'Contactos',
    icon: 'MessageCircleIcon',
    children: [
      {
        route: 'contacts',
        title: 'Contactos',
        icon: 'MessageCircleIcon'
      },
      {
        route: 'contacts-newsletter',
        title: 'Contactos Newsletter',
        icon: 'MessageCircleIcon'
      },
      {
        route: 'contacts-enterprise',
        title: 'Contactos Inmobiliaria',
        icon: 'MessageCircleIcon'
      },
      {
        route: 'contacts-form-section',
        title: 'Contactos Formulario',
        icon: 'MessageCircleIcon'
      },
      {
        route: 'contacts-form-home-section',
        title: 'Contactos Formulario Home',
        icon: 'MessageCircleIcon'
      }
    ]
  }
  // {
  //   header: 'Planes',
  //   icon: 'PieChartIcon',
  //   children: [
  //     {
  //       route: 'plans-create',
  //       title: 'Contrata tu plan',
  //       icon: 'PlusSquareIcon',
  //     },
  //   ]
  // },
]
